export default () => {
    useHead({
        link: [
            {
                rel: 'shortcut icon',
                href: '/favicon.ico'
            }
        ]
    });
};

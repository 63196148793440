import type { RouterConfig } from '@nuxt/schema';

// https://router.vuejs.org/guide/advanced/scroll-behavior.html
export default <RouterConfig> {
    scrollBehavior(to, from) {
        console.log(to.path, from.path);
        if (to.hash && to.path === from.path) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ el: to.hash, top: 80, behavior: 'smooth' });
                }, 200); // Wait for something idfk
            });
        } else if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ el: to.hash, top: 80, behavior: 'smooth' });
                }, 500); // Wait for page transition
            });
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({ el: '#layout', top: 0, behavior: 'instant' });
            }, 500); // Wait for page transition
        });
    }
};

<template>
    <div class="the-error">
        <BaseSection>
            <h1 class="the-error__title">{{ title }}</h1>
            <h2 class="the-error__subtitle">{{ subtitle }}</h2>
            <div class="the-error__links">
                <NuxtLink :to="t('error.request-subsidy.link')">{{ t('error.request-subsidy.label') }}</NuxtLink>
                <NuxtLink :to="t('error.deadlines.link')">{{ t('error.deadlines.label') }}</NuxtLink>
                <NuxtLink :to="t('error.contact.link')">{{ t('error.contact.label') }}</NuxtLink>
                <NuxtLink :to="t('error.about.link')">{{ t('error.about.label') }}</NuxtLink>
            </div>
        </BaseSection>
    </div>
</template>

<script setup>
const props = defineProps({
    notFound: {
        type: Boolean,
        default: false,
        required: false
    }
});

const { t } = useI18n();

const mainStore = useMainStore();

const { notFound } = toRefs(props);

const title = computed(() => {
    if (notFound.value) {
        return t('error.404-title');
    } else {
        return t('error.500-title');
    }
});

const subtitle = computed(() => {
    if (notFound.value) {
        return t('error.404-subtitle');
    } else {
        return t('error.500-subtitle');
    }
});

onMounted(() => {
    mainStore.toggleNewsletterSubscribe(false);
});
</script>

<style lang="less" src="./TheError.less" scoped />

import { craftPreview, bearerToken } from '@digitalnatives/villus-plugins';
import { defaultPlugins, definePlugin } from 'villus';

export default defineGraphqlConfig(() => {
    const config = useRuntimeConfig();
    return {
        villus: {
            url: config.public.apiEndpoint,
            use: [ua(), bearerToken(config.public.apiToken), craftPreview(), ...defaultPlugins()],
        }
    };
});

/**
 * Sets the UA.
 */
const ua = () => {
    return definePlugin(({ opContext }) => {
        if (process.server) {
            opContext.headers['User-Agent'] = '@digitalnatives/graphql-client';
        }
    });
};

import revive_payload_client_4sVQNw7RlN from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/filmfonds/filmfonds-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/filmfonds/filmfonds-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/filmfonds/filmfonds-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/filmfonds/filmfonds-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_u0Vr1vkWtR from "/builds/filmfonds/filmfonds-frontend/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_6wEQMY3tee from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_u0Vr1vkWtR,
  plugin_w2VlvAEcpa,
  nuxt_plugin_6wEQMY3tee
]